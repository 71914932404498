import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import Logo from "../../Assets/Images/LogoLight.png";
import ConnectWallet from "./ConnectWallet";
import { Dropdown, Menu, Input, Modal, Form, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaPiggyBank, FaPowerOff } from "react-icons/fa";
import { SlOptions } from "react-icons/sl";
import Authenticator from "../Auth/Authenticator/Authenticator";
import toast from "react-hot-toast";
import { BaseUrl } from "../Common/Apis/Apis";
import axios from "axios";
import { CgLockUnlock } from "react-icons/cg";
import Swal from "sweetalert2";
import { TbPasswordFingerprint } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsReverseListColumnsReverse } from "react-icons/bs";
import { useWallet } from "@suiet/wallet-kit";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";

const { Password } = Input;
function Hadder() {
  const navigate = useNavigate();
  const [enableAuth, setEnableAuth] = useState("false");
  const [userBalances, setUserBalances] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const apiToken = sessionStorage.getItem("token");
const UserToken = JSON.parse(sessionStorage.getItem("UserAuth"));
  const wallet = useWallet();

  React.useEffect(() => {
    const storedAuth = sessionStorage.getItem("enable_auth");
    setEnableAuth(storedAuth ? JSON.parse(storedAuth) : "false");
  }, []);
  const handleTransaction = () => {
    navigate("/admin/admin_transaction");
  };
  const handleAllTransaction = () => {
    navigate("/admin/all_transaction");
  };
  const handleStakeTransaction = () => {
    navigate("/admin/stake_transaction");
  };
  const handleDepositTransaction = () => {
    navigate("/admin/deposit_transaction");
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, stay login!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("token");
        navigate("/admin/login");
        Swal.fire({
          title: "Logged Out",
          text: "You have been logged out successfully.",
          icon: "success",
        });
      }
    });
  };

  const handleDisableAuth = async () => {
    const apiToken = sessionStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + "admin/disableAndEnable_Auth",
        { auth: false },
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken ? apiToken.replace(/^"|"$/g, "") : "",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Authenticator disabled successfully!");
      sessionStorage.setItem("enable_auth", JSON.stringify("false"));
      setEnableAuth("false");
    } catch (error) {
      toast.error("Failed to disable authenticator.");
      console.error(error.response ? error.response.data : error.message);
    }
  };

  const handleButtonClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to disable the authenticator. This may affect account security!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disable it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDisableAuth();
        Swal.fire("Disabled!", "Authenticator has been disabled.", "success");
      }
    });
  };

  const handleChangePwd = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { oldPassword, newPassword, confirmPassword } = values;
        if (newPassword !== confirmPassword) {
          toast.error("New passwords do not match.");
          return;
        }
        const apiToken = sessionStorage.getItem("token");
        try {
          const response = await axios.post(
            BaseUrl + "admin/changePassword",
            {
              oldPassword: oldPassword,
              newPassword: newPassword,
            },
            {
              headers: {
                accept: "application/json",
                Authorization: apiToken ? apiToken.replace(/^"|"$/g, "") : "",
                "Content-Type": "application/json",
              },
            }
          );
          toast.success("Password changed successfully!");
          setIsModalVisible(false);
        } catch (error) {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
          toast.error("Failed to change password. Please try again.");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  async function DepositUser() {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getTotalUserPosition`,
        {
          headers: {
            Authorization: UserToken?.token || null,
          },
        }
      );
      setUserBalances(response.data?.data?.totalUserPositionAmount2 || "0.0");
    } catch (error) {
      console.error("Error fetching deposit transactions:", error);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const page = useLocation().pathname;

  useEffect(() => {
    DepositUser();
  }, []);

  return (
    <>
      <Navbar expand="lg" className="px-2 bg-body-nav px-md-5">
        <Container fluid>
          <Navbar.Brand className="text-white d-flex align-items-center ms-md-3">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                className="image-fluid me-3"
                width={150}
              />
              </Link>
              <span class="beta-logo">Beta</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <GiHamburgerMenu className="text-light" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="mb-2 navbar-nav me-auto mb-lg-0">
              <li className="nav-item">
                <Link
                  to={"/vault"}
                  className={`nav-link ${
                    page === "/vault" ? "text-white" : ""
                  }`}
                >
                  Vault
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/stake"}
                  className={`nav-link ${
                    page === "/stake" ? "text-white" : ""
                  }`}
                >
                  Stake
                </Link>
              </li>
              {userBalances <= 0.1 ||
                ("0.1" && (
                  <li
                    className={`nav-item ${
                      wallet.connected && wallet.account?.address
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <Link
                      to={"/transaction_history"}
                      className={`nav-link ${
                        page === "/transaction_history" ? "text-white" : ""
                      }`}
                    >
                      Transaction History
                    </Link>
                  </li>
                ))}
              <li className="nav-item">
                <a
                  href="https://suidollar.gitbook.io/overview"
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </li>
            </ul>
            <div className="d-flex align-items-center">
              <ConnectWallet />
              {wallet.connected && wallet.account?.address && apiToken && (
                <Dropdown
                  placement="bottom"
                  trigger={["click"]}
                  className="px-2 py-1 mx-3 border text-light rounded-circle"
                  overlay={
                    <Menu>
                      <>
                        <Menu.Item key="auth">
                          {enableAuth === "true" ? (
                            <span
                              onClick={handleButtonClick}
                              style={{ cursor: "pointer" }}
                            >
                              <CgLockUnlock className="text-danger me-2" />{" "}
                              Disable Authenticator
                            </span>
                          ) : (
                            <Authenticator />
                          )}
                        </Menu.Item>
                        <Menu.Item key="changePwd" onClick={handleChangePwd}>
                          <TbPasswordFingerprint className="text-primary me-2" />{" "}
                          Change Password
                        </Menu.Item>
                        <Menu.Item key="Withdraw" onClick={handleTransaction}>
                          <BiMoneyWithdraw className="text-success me-2" />{" "}
                          Withdraw Request
                        </Menu.Item>
                        <Menu.Item
                          key="DepositTransaction"
                          onClick={handleDepositTransaction}
                        >
                          <FaPiggyBank className="text-success me-2" /> Deposit
                          Transaction
                        </Menu.Item>
                        <Menu.Item
                          key="AllTransaction"
                          onClick={handleAllTransaction}
                        >
                          <BsReverseListColumnsReverse className="text-success me-2" />{" "}
                          All Transaction
                        </Menu.Item>
                        <Menu.Item
                          key="StakeTransaction"
                          onClick={handleStakeTransaction}
                        >
                          <GrTransaction className="text-success me-2" />{" "}
                          Stake Transaction
                        </Menu.Item>
                        <Menu.Item key="logout" onClick={handleLogout}>
                          <FaPowerOff className="text-danger me-2" /> Logout
                        </Menu.Item>
                      </>
                    </Menu>
                  }
                >
                  <span style={{ cursor: "pointer" }}>
                    <SlOptions />
                  </span>
                </Dropdown>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        title="Change Password"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Change"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              { required: true, message: "Please enter your old password!" },
            ]}
          >
            <Password placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please enter your new password!" },
            ]}
          >
            <Password placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            rules={[
              { required: true, message: "Please confirm your new password!" },
            ]}
          >
            <Password placeholder="Confirm New Password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Hadder;
