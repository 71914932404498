import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal } from "antd";
import { useWallet } from "@suiet/wallet-kit";
import { Transaction } from "@mysten/sui/transactions";
import { FaUserLock } from "react-icons/fa";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
const AdminStake = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [days, setDays] = useState("");
  const [coolingTime, setCoolingTime] = useState(0);
  const milisecound = 86400000;
  const wallet = useWallet();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCooing = async () => {
    if (!days || isNaN(days) || Number(days) <= 0) {
      toast.error("Please enter a valid Days.");
      return;
    }
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const CoolDown =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COOLDOWN_PERIOD
          : process.env.REACT_APP_PROD_COOLDOWN_PERIOD;
      const FairmileModule = "teststaking";
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::updateCoolingTime`,
        arguments: [txb.pure.u64(days), txb.object(CoolDown)],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      fetchCoolingPeriod();
      handleCancel();
      toast.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      toast.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };
  const fetchCoolingPeriod = async () => {
    const CoolDown =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_DEV_COOLDOWN_PERIOD
      : process.env.REACT_APP_PROD_COOLDOWN_PERIOD;
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          CoolDown,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setCoolingTime(
        response?.data?.result?.data?.content?.fields?.cooling_time
      );
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching object:", error);
      return "0.00";
    }
  };
  useEffect(() => {
    fetchCoolingPeriod();
  }, []);

  const coolingEndTime = moment(Number(coolingTime)).valueOf();
  return (
    <>
      <span onClick={showModal}>
        <FaUserLock className="text-success mx-3 fs-5" />
      </span>
      <Modal
        title="Cooling Period"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <p>
          <strong>Current Cooldown Period:</strong>
          {coolingEndTime / milisecound} Days
        </p>
        <div className="text-center">
          <Input
            value={days}
            onChange={(e) => setDays(e.target.value)}
            required
            placeholder="Enter No. of days..."
          />
          <Button className="mt-3" onClick={handleCooing}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default AdminStake;
