import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { SlOptions } from "react-icons/sl";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Transaction } from "@mysten/sui/transactions";
import { useWallet } from "@suiet/wallet-kit";
import moment from "moment";
import SUTOKEN from "../../Assets/Images/LogoRoundSky.png";
import { ClockCircleOutlined, DollarOutlined } from "@ant-design/icons";
import "./Stake.css";
import toast from "react-hot-toast";
const StakeModal = ({ transactionDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stakeId, setStakeId] = useState("");
  const [stakeInfo, setStakeInfo] = useState("");
  const [coolingTime, setCoolingTime] = useState("");
  const wallet = useWallet();
  const CoolDown =
  process.env.REACT_APP_ENV === "DEV"
    ? process.env.REACT_APP_DEV_COOLDOWN_PERIOD
    : process.env.REACT_APP_PROD_COOLDOWN_PERIOD;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchCreatedObject = async () => {
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getTransactionBlock",
        params: [
          transactionDetail?.digest,
          {
            showInput: true,
            showRawInput: false,
            showEffects: true,
            showEvents: true,
            showObjectChanges: false,
            showBalanceChanges: false,
            showRawEffects: false,
          },
        ],
      });
      const objectId =
        response?.data?.result?.effects?.created[0]?.reference?.objectId;
      if (objectId) {
        setStakeId(objectId);
      }
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching transaction block:", error);
      return "0.00";
    }
  };

  const fetchStakeStatus = async () => {
    if (!stakeId) return;
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          stakeId,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setStakeInfo(response?.data?.result?.data?.content?.fields || 0);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching object:", error);
      return "0.00";
    }
  };
  const fetchCoolingPeriod = async () => {
    if (!stakeId) return;
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          CoolDown,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setCoolingTime(
        response?.data?.result?.data?.content?.fields?.cooling_time
      );
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching object:", error);
      return "0.00";
    }
  };

  useEffect(() => {
    if (showModal) {
      fetchCreatedObject();
      fetchCoolingPeriod();
    }
  }, [showModal]);

  useEffect(() => {
    fetchStakeStatus();
    fetchCoolingPeriod();
  }, [stakeId]);

  const handleUnStakeConfirm = () => {
    Modal.confirm({
      title: "Unstaking Confirmation",
      content:
        "Are you certain you want to proceed with unstaking? Please note that if you choose to unstake, you will no longer be eligible to claim rewards and will have to wait until the cooling period ends to withdraw.",
      okText: "Yes, Unstake",
      okType: "danger",
      cancelText: "Cancel",
      onOk: handleUnStake,
    });
  };

  const handleUnStake = async () => {
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::unstake`,
        arguments: [txb.object(stakeId), txb.object("0x06")],
        typeArguments: [CoinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      toast.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };
  const handleWithdraw = async (stakedId) => {
    const amount = Math.abs(
      transactionDetail?.effects?.balanceChanges?.nodes[1]?.amount || 0
    );

    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      toast.error("Please enter a valid amount to stake.");
      return;
    }
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;

      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::withdrawUser`,
        arguments: [
          txb.object(stakeId),
          txb.pure.u64(amount),
          txb.object(stakedId),
          txb.object("0x06"),
        ],
        typeArguments: [CoinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      toast.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };

  const handleReward = async (coinTypeId) => {
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
          const Counter =
          process.env?.REACT_APP_MODE === "DEV"
            ? process.env?.REACT_APP_DEV_STAKE_COUNTER
            : process.env?.REACT_APP_PROD_STAKE_COUNTER;
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::claimRewards`,
        arguments: [
          txb.object(stakeId),
          txb.object(coinTypeId),
          txb.object(Counter),
          txb.object("0x06"),
        ],
        typeArguments: [CoinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      toast.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };

  // const currentTime = moment().valueOf();
  // const coolingEndTime = moment(
  //   Number(stakeInfo.unstaked_timestamp) + Number(coolingTime)
  // ).valueOf();
  // const stakedEndTime = moment(
  //   Number(transactionDetail.timestamp) + Number(coolingTime)
  // ).valueOf();
  const formatAmount = (amount) => Math.abs((amount || 0) / 1e9) + " SUTOKEN";
  const formatDate = (timestamp) =>
    moment(Number(timestamp)).format("DD/MM/YYYY HH:mm:ss");
  const formatAddress = (address) =>
    address ? `${address.slice(0, 6)}...${address.slice(-4)}` : "";
  const formatHash = (hash) =>
    hash ? `${hash.slice(0, 6)}...${hash.slice(-4)}` : "";

  return (
    <>
      <SlOptions onClick={showModal} />
      <Modal
        title={<h2 className="modal-title">Stake Detail</h2>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="stake-detail-modal"
      >
        <div className="stake-detail-content">
          <div className="token-image mb-4 text-center">
            <img src={SUTOKEN} alt="SUTOKEN" width={80} />
          </div>
          <div className="stake-info mb-4">
            <div className="info-row">
              <DollarOutlined className="info-icon" />
              <strong>Amount Staked:</strong>{" "}
              {formatAmount(
                transactionDetail?.effects?.balanceChanges?.nodes[1]?.amount
              )}
            </div>
            <div className="info-row">
              <ClockCircleOutlined className="info-icon" />
              <strong>Stake Timestamp:</strong>{" "}
              {formatDate(stakeInfo?.staked_timestamp)}
            </div>
            {stakeInfo.is_unstaked && (
              <div className="info-row">
                <ClockCircleOutlined className="info-icon" />
                <strong>Unstake Timestamp:</strong>{" "}
                {formatDate(stakeInfo.unstaked_timestamp)}
              </div>
            )}
            <div className="info-row">
              <ClockCircleOutlined className="info-icon" />
              <strong>Transaction Hash:</strong>{" "}
              {formatAddress(transactionDetail?.digest)}
            </div>
          </div>
          <div className="actions d-flex justify-content-around mt-4">
            {/* {stakeInfo?.unstaked_timestamp == 0 && stakedEndTime <= currentTime && (
            )} */}
            <Button key="unstake" type="danger" onClick={handleUnStakeConfirm}>
              Unstake
            </Button>
            {/* {(stakeInfo?.unstaked_timestamp == 0 &&
            stakeInfo.staked_amount == 0 &&
            (Number(stakeInfo.unstaked_timestamp) + coolingTime) <= currentTime) && (
            )} */}
            <Button key="withdraw" type="primary"  onClick={() => handleWithdraw(stakeInfo?.id?.id)}>
              Withdraw
            </Button>
            {/* {stakeInfo?.unstaked_timestamp == 0 && stakedEndTime <= currentTime && transactionDetail.timestamp == 0 &&(
            )} */}
            <Button key="claim" type="success" onClick={() =>handleReward(stakeInfo?.id?.id)}>
              Claim Reward
            </Button>
          </div>
        </div>
      </Modal>{" "}
    </>
  );
};
export default StakeModal;
