import { Card, Dropdown, Flex, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Badge, Container, Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import Stake from "./Stake";
import SDTOKEN from "../../Assets/Images/LogoRound.png";
import { useWallet } from "@suiet/wallet-kit";
import StakeModal from "./StakeModal";
import {
  ApolloClient,
  gql,
  InMemoryCache,
  ApolloProvider,
  useQuery,
} from "@apollo/client";
import { SuiGraphql } from "../../Components/Common/Apis/Apis";
import axios from "axios";

const client = new ApolloClient({
  uri: SuiGraphql,
  cache: new InMemoryCache(),
});
const TRANSACTION_QUERY = gql`
  query ($objectID: SuiAddress!, $senderAddress: SuiAddress!) {
    transactionBlocks(
      filter: { changedObject: $objectID, sentAddress: $senderAddress }
    ) {
      nodes {
        digest
        sender {
          address
        }
        effects {
          balanceChanges {
            nodes {
              owner {
                address
              }
              amount
            }
          }
        }
      }
    }
  }
`;

const StakeDashboardInfo = () => {
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [stakeTransactions, setStakeTransactions] = useState([]);
  const [coinObjectId, setCoinObjectId] = useState("");
  const [totalUserStake, setTotalUserStake] = useState();

  const wallet = useWallet();
  const walletAddress = wallet.address;
  const stakeCounter =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_DEV_STAKE_COUNTER
      : process.env.REACT_APP_PROD_STAKE_COUNTER;
  const { loading: queryLoading, data } = useQuery(TRANSACTION_QUERY, {
    variables: {
      objectID: stakeCounter,
      senderAddress: walletAddress,
    },
    client,
  });
  useEffect(() => {
    fetchBalance();
  }, []);
  useEffect(() => {
    if (!queryLoading && data) {
      setStakeTransactions(data.transactionBlocks.nodes);
      let totalAmount = 0;
      stakeTransactions.forEach((transaction) => {
        const amount =
          Number(transaction?.effects?.balanceChanges?.nodes[1]?.amount) || 0;
        totalAmount += amount;
      });
      const positiveValue = Math.abs(totalAmount) / 1e9;
      setTotalBalance(positiveValue);
      setLoading(false);
    }
    fetchBalance();
    fetchTotalUserStake();
  }, [queryLoading, data]);
  const formatAmount = (amount) => {
    const positiveAmount = Math.abs(amount || 0);
    return (positiveAmount / 1e9).toFixed(2) + " SUTOKEN";
  };
  const stakeItems = [
    {
      key: "1",
      label: <Stake ObjectId={coinObjectId} />,
    },
  ];

  const fetchBalance = async () => {
    const CoinType =
      process.env.REACT_APP_ENV === "DEV"
        ? process.env.REACT_APP_DEV_COIN
        : process.env.REACT_APP_PROD_COIN;
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [walletAddress, CoinType, null, 50],
      });
      const data = response?.data?.result?.data || [];
      const totalBalance = data.reduce(
        (acc, obj) => acc + parseFloat(obj.balance || "0"),
        0
      );
      setCoinObjectId(data);
      setBalance(totalBalance / 1e9);
    } catch (error) {
      console.error("Error fetching balance:", error);
      return {
        balance: "0.00",
        coinObjectId: "",
      };
    }
  };
  const fetchTotalUserStake = async () => {
    const stakeCounter =
      process.env.REACT_APP_ENV === "DEV"
        ? process.env.REACT_APP_DEV_STAKE_COUNTER
        : process.env.REACT_APP_PROD_STAKE_COUNTER;
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          stakeCounter,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setTotalUserStake(
        response?.data?.result?.data?.content?.fields?.total_staked
      );
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };

  return (
    <Container>
      <div>
        <h1 className="vault-heading">Stake SUTOKEN</h1>
        <Flex gap="middle" align="start" vertical>
          <Card title="Stake Information" className="vault-table">
            <Table>
              <tbody>
                <tr className="text-start">
                  <td>Total Staked</td>
                  <td>
                    {loading ? (
                      <Skeleton.Input active={true} size="small" />
                    ) : (
                      `${totalBalance?.toFixed(2)} SUTOKEN`
                    )}
                  </td>
                </tr>
                <tr className="text-start">
                  <td>Total User Staked</td>
                  <td>
                    {loading ? (
                      <Skeleton.Input active={true} size="small" />
                    ) : (
                      `${(totalUserStake / 1e9)?.toFixed(2)} SUTOKEN`
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Flex>
        <Card title="Staked Balances" className="my-4">
          <Table>
            <thead>
              <tr className="text-start">
                <th>Asset</th>
                <th>Balance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-start">
                <td>
                  <img
                    src={SDTOKEN}
                    alt="SDToken"
                    className="me-2 img-fluid"
                    width={30}
                  />
                  <span>SD TOKEN</span> <Badge>Stake</Badge>
                </td>
                <td>{Number(balance)?.toFixed(2) || "0.0"}</td>
                <td>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottom"
                    menu={{ items: stakeItems }}
                  >
                    <BsThreeDots style={{ cursor: "pointer" }} />
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
        <Card className="mb-5">
          {stakeTransactions && stakeTransactions.length > 0 ? (
            <Table hover responsive>
              <thead>
                <tr className="text-start">
                  <th>Asset</th>
                  <th>Staked Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {stakeTransactions.map((transaction, index) => (
                  <tr key={index} className="text-success">
                    <td>
                      <img
                        src={SDTOKEN}
                        alt="SDToken"
                        className="me-2 img-fluid"
                        width={30}
                      />
                      <span>SD TOKEN</span> <Badge>Staked</Badge>
                    </td>
                    <td>
                      {formatAmount(
                        transaction?.effects?.balanceChanges?.nodes[1]?.amount
                      )}
                    </td>
                    <td>
                      <StakeModal transactionDetail={transaction} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-muted text-center">
              No transactions found for this wallet.
            </p>
          )}
        </Card>
      </div>
    </Container>
  );
};

const StakeDashboard = () => (
  <ApolloProvider client={client}>
    <StakeDashboardInfo />
  </ApolloProvider>
);

export default StakeDashboard;
