import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import { CiEdit } from "react-icons/ci";
import { useWallet } from "@suiet/wallet-kit";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseUrl } from "../../Common/Apis/Apis";

const FormAssetdetail = ({
  title,
  marginAsset,
  PositionPriceAsset,
  PositionSizeAsset,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [positionSize, setPositionSize] = useState("");
  const [positionEntryPrice, setPositionEntryPrice] = useState("");
  const [margin, setMargin] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const Coin = title === "Bluefin SUI-PERP" ? "sui" : "eth";

  const apiToken = sessionStorage?.getItem("token");
  const fetchCoinDetails = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `admin/getCoinDetails?type=${Coin}`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken.replace(/^"|"$/g, ""),
          },
        }
      );
      const { margin, position_entry_price, position_size } =
        response.data?.data;
      setPositionSize(position_size || "");
      setPositionEntryPrice(position_entry_price || "");
      setMargin(margin || "");
      marginAsset(margin || "");
      PositionPriceAsset(position_entry_price || "");
      PositionSizeAsset(position_size || "");
    } catch (error) {
      console.error("Error fetching coin details:", error);
    }
  };
  useEffect(() => {
    fetchCoinDetails("sui");
    fetchCoinDetails("eth");
  }, [isModalOpen]);
  const handleOk = async () => {
    const data = {
      type: Coin,
      margin: margin,
      position_entry_price: positionEntryPrice,
      position_size: positionSize,
    };
    const adminTokenToken = sessionStorage?.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + "admin/saveCoinDetails",
        data,
        {
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setIsModalOpen(false);
      toast.success(
        response.data.message || "Coin details saved successfully!"
      );
    } catch (error) {
      console.error("Error saving coin details:", error);
      toast.error("An error occurred while saving coin details.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const wallet = useWallet();

  return (
    <>
      <span
        className="me-2"
        disabled={!wallet?.connected && !wallet?.account?.address}
        onClick={showModal}
        style={{ cursor: "pointer" }}
      >
        <CiEdit />
      </span>
      <Modal
        title={<h5>{title}</h5>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Position Size">
            <Input
              value={positionSize || "0"}
              onChange={(e) => setPositionSize(e.target.value)}
              
            />
          </Form.Item>
          <Form.Item label="Position Entry Price">
            <Input
              value={positionEntryPrice || "0"}
              onChange={(e) => setPositionEntryPrice(e.target.value)}
              prefix="$"
            />
          </Form.Item>
          <Form.Item label="Margin">
            <Input
              value={margin || "0"}
              onChange={(e) => setMargin(e.target.value)}
              prefix="$"
            />
          </Form.Item>
          <Form.Item className="text-center">
            <Button className="supplyBtn w-100" onClick={handleOk}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FormAssetdetail;
