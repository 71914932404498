import { Button, Card, Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { FiRefreshCcw } from "react-icons/fi";
import SUIDoller from "../../Assets/Images/LogoRound.png";
import { Transaction } from "@mysten/sui/transactions";
import { useWallet } from "@suiet/wallet-kit";
import AdminDepositReward from "../../Components/Modal/AdminDepositReward/AdminDepositReward";
import AdminStake from "../../Components/Modal/AdminStake/AdminStake";
import axios from "axios";
import { Copy } from "../../Components/Copy/Copy";
import moment from "moment";
import toast from "react-hot-toast";

const RewardDeposit = ({ CoinObjectId }) => {
  const [rewardDetail, setRewardDetail] = useState();
  const wallet = useWallet();

  const fetchDepositReward = async () => {
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          "0x79fcbb42dd141e78be556b472bc47e89c599f287861f99344e7ba0a0d8dcec70",
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setRewardDetail(response?.data?.result?.data?.content?.fields);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching object:", error);
      return "0.00";
    }
  };

  useEffect(() => {
    fetchDepositReward();
  }, []);

  const rewardwithdraw = async (rewardAmount, rewardID) => {

    if (!rewardAmount || isNaN(rewardAmount) || Number(rewardAmount) <= 0) {
      toast.error("Please enter a valid amount to stake.");
      return;
    }
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::withdrawRewards`,
        arguments: [
          txb.object(rewardID),
          txb.pure.u64(rewardAmount),
        ],
        typeArguments: [CoinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      toast.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card
          title={
            <div className="d-flex justify-content-between align-items-center">
              Deposit Reward
              <h6>
                <FiRefreshCcw
                  style={{ cursor: "pointer" }}
                  // onClick={handleReload}
                  // className={isRotating ? "rotateReload" : ""}
                />
                <AdminStake />
                <AdminDepositReward SuiObjectId={CoinObjectId} />
              </h6>
            </div>
          }
        >
          <Table responsive>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Total Reward</th>
                <th>Remaining Reward</th>
                <th>Reward Duration</th>
                <th>Reward Time</th>
                <th>Reward ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={SUIDoller} alt="" className="me-2 img-fluid" width={32} />
                  SUTOKEN
                  <Badge pill bg="success" className="mx-1">
                    Reward
                  </Badge>
                </td>
                <td>{rewardDetail?.total_rewards / 1e9}</td>
                <td>{(rewardDetail?.rewards_amount / 1e9)?.toFixed(2)}</td>
                <td>
                  {rewardDetail?.reward_duration
                    ? rewardDetail.reward_duration / 86400000
                    : 0}{" "}
                  Days
                </td>
                <td>
                  {rewardDetail?.deposit_timestamp
                    ? moment(Number(rewardDetail.deposit_timestamp)).format(
                        "DD/MM/YYYY, hh:mm:ss a"
                      )
                    : ""}
                </td>
                <td>
                  {rewardDetail?.id?.id.slice(0, 6)}...
                  {rewardDetail?.id?.id.slice(-4)}{" "}
                  <Copy content={rewardDetail?.id?.id} />
                </td>
                <td>
                  <Button
                    type="text"
                    onClick={() => rewardwithdraw(rewardDetail?.rewards_amount, rewardDetail?.id?.id )}
                  >
                    Withdraw Reward
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default RewardDeposit;
