import { useWallet } from "@suiet/wallet-kit";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ApolloClient, gql, InMemoryCache, useQuery } from "@apollo/client";
import { Copy } from "../../Components/Copy/Copy";
import { SuiGraphql } from "../../Components/Common/Apis/Apis";
import moment from "moment";

const client = new ApolloClient({
  uri: SuiGraphql,
  cache: new InMemoryCache(),
});

const TRANSACTION_QUERY = gql`
  query ($objectID: SuiAddress!, $senderAddress: SuiAddress!) {
    transactionBlocks(
      filter: { changedObject: $objectID, sentAddress: $senderAddress }
    ) {
      nodes {
        digest
        sender {
          address
        }
        effects {
          balanceChanges {
            nodes {
              owner {
                address
              }
              amount
            }
          }
        }
      }
    }
  }
`;

const AllStake = () => {
  const [stakeTransactin, setStakeTransactin] = useState([]);
  const [stakeDetails, setStakeDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const stakeCounter =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_DEV_STAKE_COUNTER
      : process.env.REACT_APP_PROD_STAKE_COUNTER;
  const wallet = useWallet();
  const walletAddress = wallet.address;
  const { data, loading: queryLoading } = useQuery(TRANSACTION_QUERY, {
    variables: {
      objectID: stakeCounter,
      senderAddress: walletAddress,
    },
    client,
  });

  const fetchCreatedObject = async (transactionDigest) => {
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getTransactionBlock",
        params: [
          transactionDigest,
          {
            showInput: true,
            showRawInput: false,
            showEffects: true,
            showEvents: true,
            showObjectChanges: false,
            showBalanceChanges: false,
            showRawEffects: false,
          },
        ],
      });
      return (
        response?.data?.result?.effects?.created[0]?.reference?.objectId || null
      );
    } catch (error) {
      console.error("Error fetching transaction block:", error);
      return null;
    }
  };

  const fetchStakeStatus = async (objectId) => {
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          objectId,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      return response?.data?.result?.data?.content?.fields || null;
    } catch (error) {
      console.error("Error fetching object:", error);
      return null;
    }
  };

  const fetchStakeDetails = async () => {
    if (!data?.transactionBlocks?.nodes) return;
    const transactions = data.transactionBlocks.nodes;
    const fetchedDetails = await Promise.all(
      transactions.map(async (transaction) => {
        const objectId = await fetchCreatedObject(transaction.digest);
        if (!objectId) return null;

        const stakeInfo = await fetchStakeStatus(objectId);
        return {
          ...transaction,
          stakeInfo,
        };
      })
    );
    setStakeDetails(fetchedDetails.filter(Boolean)); // Remove null entries
    setLoading(false);
  };
  useEffect(() => {
    if (!queryLoading && data) {
      setStakeTransactin(data.transactionBlocks.nodes);
      fetchStakeDetails();
    }
  }, [queryLoading, data]);
  const formatAmount = (amount) => {
    const positiveAmount = Math.abs(amount || 0);
    return (positiveAmount / 1e9).toFixed(2) + " SUTOKEN";
  };
  const formatHash = (hash) =>
    hash ? `${hash.slice(0, 5)}...${hash.slice(-3)}` : "";

  return (
    <div>
      {loading ? (
        <div className="text-center my-5">
          <Spin size="large" />
        </div>
      ) : stakeDetails && stakeDetails.length > 0 ? (
        <Table hover responsive>
          <thead>
            <tr className="text-start">
              <th>Stake Amount</th>
              <th>Sender</th>
              <th>Stake Time</th>
              <th>Unstake Time</th>
              <th>Transaction Hash</th>
            </tr>
          </thead>
          <tbody>
            {stakeDetails.map((transaction, index) => (
              <tr key={index} className="text-success">
                <td>
                  {formatAmount(
                    transaction?.effects?.balanceChanges?.nodes[1]?.amount
                  )}
                </td>
                <td>
                  {" "}
                  {formatHash(transaction?.stakeInfo?.user_address) || "--"}
                </td>
                <td>
                  {transaction?.stakeInfo?.staked_timestamp
                    ? moment(
                        parseInt(transaction?.stakeInfo?.staked_timestamp)
                      ).format("YYYY-MM-DD HH:mm:ss")
                    : "--"}
                </td>
                <td>
                  {transaction?.stakeInfo?.unstaked_timestamp &&
                  transaction?.stakeInfo?.unstaked_timestamp !== "0"
                    ? moment(
                        parseInt(transaction?.stakeInfo?.unstaked_timestamp)
                      ).format("YYYY-MM-DD HH:mm:ss")
                    : "--"}
                </td>

                <td>
                  {formatHash(transaction.digest)}{" "}
                  <Copy content={transaction.digest} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-muted text-center my-5">
          No transactions found for this wallet.
        </p>
      )}
    </div>
  );
};

export default AllStake;
