import { Transaction } from "@mysten/sui/transactions";
import { useWallet } from "@suiet/wallet-kit";
import { Form, Input, message, Modal, Select } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { GiPayMoney } from "react-icons/gi";

const AdminDepositReward = ({ SuiObjectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [days, setDays] = useState("");
  const [coinObjectId, setCoinObjectId] = useState([]);
  const [selectedToken, setSelectedToken] = useState("SUTOKEN");
  const wallet = useWallet();
  const walletAddress = wallet?.address;
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  useEffect(() => {
    fetchBalance();
  }, [walletAddress, selectedToken]);

  const fetchBalance = async () => {
    let CoinType;
    if (selectedToken === "SUTOKEN") {
      CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
    } else {
      CoinType = "0x2::sui::SUI";
    }
    try {
      const response = await axios.post("https://rpc.devnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [walletAddress, CoinType, null, 50],
      });
      const data = response?.data?.result?.data || [];
      setCoinObjectId(data);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const handleDeposit = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      toast.error("Please enter a valid deposit amount.");
      return;
    }
    if (!days || isNaN(days) || Number(days) <= 0) {
      toast.error("Please enter a valid deposit duration (in days).");
      return;
    }

    try {
      const txb = new Transaction();
      const amountInNanoUnits = Number(amount) * 1e9;

      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const SUTOKENCoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;

      const SUICoinType = "0x2::sui::SUI";
      const coinType =
        selectedToken === "SUTOKEN" ? SUTOKENCoinType : SUICoinType;
      let coinObject;
      if (selectedToken === "SUI") {
        [coinObject] = txb.splitCoins(txb.gas, [amountInNanoUnits]);
      } else {
        if (!coinObjectId?.length) {
          toast.error("No SUTOKEN objects available for deposit.");
          return;
        }
        let baseObj = txb.object(coinObjectId[0].coinObjectId);
        for (let i = 1; i < coinObjectId.length; i++) {
          txb.mergeCoins(baseObj, [txb.object(coinObjectId[i].coinObjectId)]);
        }
        [coinObject] = txb.splitCoins(baseObj, [amountInNanoUnits]);
      }
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::depositRewards`,
        arguments: [
          txb.object(coinObject),
          txb.pure.u64(days),
          txb.object("0x06"),
        ],
        typeArguments: [coinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Rewards deposited successfully!");
      console.log("Transaction result:", result);
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Deposit failed. Please try again.");
      console.error("Deposit error:", error);
    }
  };

  return (
    <>
      <GiPayMoney onClick={showModal} className="fs-5" />
      <Modal
        title={<h2 className="modal-title">Deposit Reward</h2>}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="deposit-reward-modal"
      >
        <div className="deposit-form-content">
          <Form layout="vertical">
            <Form.Item label="Select Token" required>
              <Select
                value={selectedToken}
                onChange={(value) => setSelectedToken(value)}
                options={[
                  { label: "SUI", value: "SUI" },
                  { label: "SUTOKEN", value: "SUTOKEN" },
                ]}
                className="input-field"
              />
            </Form.Item>
            <Form.Item label="Deposit Duration (Days)" required>
              <Input
                value={days}
                onChange={(e) => setDays(e.target.value)}
                placeholder="Enter days..."
                type="number"
                min="1"
                className="input-field"
              />
            </Form.Item>
            <Form.Item label="Deposit Reward Amount" required>
              <Input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter deposit reward amount..."
                type="number"
                min="0"
                className="input-field"
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                className="submit-button"
                size="large"
                onClick={handleDeposit}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AdminDepositReward;
