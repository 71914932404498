import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

// Apollo Client setup
const client = new ApolloClient({
  uri: 'https://sui-testnet.mystenlabs.com/graphql', // Sui GraphQL API endpoint
  cache: new InMemoryCache(),
});

// GraphQL Query to fetch transaction details
const TRANSACTION_QUERY = gql`
  query ($objectID: SuiAddress!) {
    transactionBlocks(filter: { changedObject: $objectID }) {
      nodes {
        digest
        sender {
          address
        }
        effects {
          balanceChanges {
            nodes {
              owner {
                address
              }
              amount
            }
          }
        }
      }
    }
  }
`;

const Transactions = ({ objectID }) => {
  const { loading, error, data } = useQuery(TRANSACTION_QUERY, {
    variables: { objectID },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Transaction Details for Object ID: {objectID}</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Transaction Digest</th>
            <th>Sender Wallet Address</th>
            <th>Balance Changes</th>
          </tr>
        </thead>
        <tbody>
          {data.transactionBlocks.nodes.map((transaction, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{transaction.digest}</td>
              <td>{transaction.sender.address}</td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Owner</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.effects.balanceChanges.nodes.map((change, idx) => (
                      <tr key={idx}>
                        <td>{change.owner?.address || 'N/A'}</td>
                        <td>{change.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const StakeTransaction = () => {
  const objectID = '0xf6dbaf1e3acd5dc742efd7511d596993bd17c3eb8915917224bbbd1c9cb58e63';

  return (
    <ApolloProvider client={client}>
      <Transactions objectID={objectID} />
    </ApolloProvider>
  );
};

export default StakeTransaction;
